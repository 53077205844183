<template>
    <v-dialog v-model="addDialog" persistent transition="scale-transition" max-width="1200px">
        <v-card class="elevation-1">
            <ValidationObserver ref="refPuest" v-slot="{ invalid, validated }">
                <v-form name="form" @submit.prevent="store">
                    <v-toolbar dark text color="primary">

                        <v-btn icon dark @click="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>

                        <v-toolbar-title>Registrar nuevo puesto</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense medium left>fas fa-save</v-icon> Guardar
                            </v-btn>
                        </v-toolbar-items>

                    </v-toolbar>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <v-text-field 
                                        label="Puesto*" 
                                        prepend-icon="fas fa-briefcase"
                                        v-model="puesto.puesto" 
                                        required
                                        :error-messages="errors" 
                                        :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>
<script>
import PuestoDataService from '@/service/catalogo/puesto/puestoDataService';
export default {
    name: 'Nuevo',
    props: {
        addDialog: { type: Boolean, default: false },
        initTable: { type: Function },
        handleModal: { type: Function }
    },
    data() {
        return {
            puesto: { puesto : '' }
        }
    },
    methods: {
        reset() {
            this.puesto = { puesto : '' };
        },
        store() {
            this.$refs.refPuest.validate().then( isValid => {
                if( isValid ){
                    PuestoDataService.store(this.puesto).then(resp => {

                        if( resp.status == 200 ) {
                            this.handleModal();
                            this.$refs.refPuest.reset();
                            this.reset();
                            this.initTable();
                            this.$swal.fire({  
                                icon:'success',
                                title: 'Catalogo de puesto',
                                text: 'El puesto se guardó correctamente',
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }

                    }).catch( error => {

                        this.$swal.fire({ 
                            icon:'error',
                            title: 'Catalogo de puesto',
                            html: error.response.data,
                            showConfirmButton: false,
                            timer: 1500
                        });

                    });
                }
            })
        }
    },
}
</script>
<template>
    <v-dialog v-model="editDialog" persistent transition="scale-transition" max-width="1200px">
        <v-card class="elevation-1">
            <ValidationObserver ref="refPuestEdit" v-slot="{ invalid, validated }" :immediate="true">
                <v-form name="form" @submit.prevent="update">
                    <v-toolbar dark text color="primary">

                        <v-btn icon dark @click="handleModalEdit">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>

                        <v-toolbar-title>Editar puesto</v-toolbar-title>
                        <v-spacer></v-spacer>

                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" dense large left>fas fa-save</v-icon> Guardar
                            </v-btn>
                        </v-toolbar-items>

                    </v-toolbar>

                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                                    <v-text-field 
                                        label="Puesto*" 
                                        prepend-icon="fas fa-briefcase"
                                        v-model="puesto.puesto" 
                                        required
                                        :error-messages="errors" 
                                        :success="valid">
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>
<script>
import PuestoDataService from '@/service/catalogo/puesto/puestoDataService';
export default {
    name: 'EditarPuesto',
    props: {
        editDialog: { type: Boolean, default: false },
        puesto: {},
        initTable: { type: Function },
        handleModalEdit: { type: Function }
    },
    methods: {
        update(){
            this.$refs.refPuestEdit.validate().then( isValid => {
                if( isValid ) {
                    PuestoDataService.update( this.puesto.id, this.puesto ).then( resp => {
                        if( resp.status == 200 ) {
                            this.handleModalEdit();
                            this.$refs.refPuestEdit.reset();
                            this.initTable();

                            this.$swal.fire({ 
                                icon : 'success',
                                title : "Puesto actualizado",
                                text: 'El puesto se actualizo correctamente',
                                showConfirmButton : false,
                                timer : 1500
                            });
                        }
                    }).catch(error => {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'No se pudo actualizar el puesto',
                            html: error.response.data,
                            showConfirmButton: false,
                            timer: 1500
                        });
                    });
                }
            })
        },
    },
}
</script>
<template>
    <v-row align="center" justify="center">
        <v-col cols="12">
            <v-card class="elevation-4">
                <v-card-title>

                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="addDialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo puesto
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-col cols="12" md="8" sm="12" class="d-flex justify-end">
                        <v-text-field 
                            v-model="search"
                            append-icon="fas fa-search"
                            label="Buscar puesto"
                            single-inline
                            hide-details
                            @keydown.enter="searchPuesto">
                        </v-text-field>
                    </v-col>
                    <Columns :headers.sync="headers"></Columns>
                </v-card-title>

                <v-data-table dense
                    :headers="filteredHeaders"
                    :items="puestos"
                    :options.sync="options"
                    :server-items-length="totalItems"
                    :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                    :loading="loading">

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn icon small @click="show(item)" v-if="!item.eliminado">
                            <v-icon small>fas fa-edit</v-icon>
                        </v-btn>

                        <v-btn icon small @click="remove(item)" v-if="!item.eliminado">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>
                    </template>

                    <template v-slot:[`item.eliminado`]="{ item }">
                        <v-chip small :color="colorStatus(item.eliminado)" label x-small dark
                        v-text="!item.eliminado ? 'Activo' : 'Inactivo'">
                        </v-chip>
                    </template>

                </v-data-table>
            </v-card>
        </v-col>
        <NuevoPuesto :addDialog.sync="addDialog" :initTable="initTable" :handleModal="handleModal" v-if="addDialog"/>
        <EditarPuesto :editDialog.sync="editDialog" :initTable="initTable" :puesto.sync="dataPuesto" :handleModalEdit="handleModalEdit" v-if="editDialog"/>
    </v-row>
</template>

<script>
import PuestoDataService from '@/service/catalogo/puesto/puestoDataService';
import NuevoPuesto from './Nuevo.vue';
import EditarPuesto from './Editar.vue';
import { getParamsPagination } from '@/utils/pagination';
import Columns from '@/components/shared/Columns.vue';

export default {
    name: 'ListaPuesto',
    components : { NuevoPuesto, EditarPuesto, Columns },
    data() {
        return {
            puestos: [],
            options: {},
            totalItems: 0,
            search: '',
            loading: true,
            addDialog: false,
            editDialog: false,

            dataPuesto: [],
            headers: [
                { text: 'Acciones', value: 'actions', sortable: false, groupable: false, show: true },
                { text: 'ID', align :' d-none', sortable: false, value: 'id' , visible: false, groupable: false, show: false },
                { text: 'Puesto', value: 'puesto', sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'eliminado', sortable: false, groupable: false, show: true },
            ],
        }
    },
    computed: {
        filteredPuestos() {
            return this.puestos.filter( item => !item.eliminado );
        },
        filteredHeaders() { return this.headers.filter( header => header.show ); },
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if ( val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage ) {
                    this.initTable( val );
                }
            },
            deep: true
        }
    },
    methods: {
        searchPuesto() {
            this.options.page = 1;
            this.initTable( this.options );
        },
        async initTable( options = {} ) {
            this.loading = true;
            const params = getParamsPagination( { options, search: this.search } );
            PuestoDataService.table( params ).then( resp => {
                const { rows, totalElements } = resp.data;
                this.puestos = rows;
                this.totalItems = totalElements;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Error al obtener los datos', text: err.response.data.error, showConfirmButton: false, timer: 3500 });
            }).finally(() => {
                this.loading = false;
            });
        },
        show(item) {
            this.dataPuesto = item;
            this.editDialog = true
        },
        remove( item ) {
            this.$swal({
                title: '¿Estas seguro?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminarlo!',
                cancelButtonText: 'Cancelar'
            }).then( result => {
                if ( result.isConfirmed ) {
                    PuestoDataService.delete( item.id ).then( resp => {
                        if( resp.status == 200 ){
                            this.initTable();
                            this.$swal.fire({ icon: 'success', text: 'Puesto eliminado', title: 'Eliminando puesto', showConfirmButton: false, timer: 1500 });
                        }
                    }).catch( err => {
                        this.$swal.fire({ title: 'Cancelado', html: err.response.data, icon: 'error', timer: 2000 });
                    });
                } else {
                    this.$swal.fire({ title: 'Cancelado', text: 'Solicitud cancelado', showConfirmButton: false, icon: 'info', timer: 2000 });
                }
            }).catch( () => {
                this.$swal.fire({ title: 'Cancelado', text: 'Proceso de validacion rechazado', showConfirmButton: false,  icon: 'info',  timer: 2000 });
            });
        },
        colorStatus(status) { return status ? "red" : "green"; },
        handleModal() { this.addDialog = !this.addDialog },
        handleModalEdit() { this.editDialog = !this.editDialog }
    },
}
</script>
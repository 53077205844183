import { http, authHeader } from '@/http-commons';

class PuestoDataService {
    getallPuesto(){
        return http.get('api/v1/puesto/getAll', { headers : authHeader() });
    }

    store( data ){
        return http.post('api/v1/puesto/create', data, { headers : authHeader() });
    }
    show( id ){
        return http.get(`api/v1/puesto/show/${ id }`, { headers : authHeader() });
    }

    update( idPuesto, data ){
        return http.put(`api/v1/puesto/update/${ idPuesto }`, data, { headers : authHeader() });
    }

    delete( id ){
        return http.delete(`api/v1/puesto/delete/${ id }`, { headers : authHeader() });
    }

    table( params ){
        return http.get('api/v1/puesto/table', { params , headers : authHeader() });
    }

}
export default new PuestoDataService();